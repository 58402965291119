<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>

    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header class="d-block">
            <b-card-title>
              {{ lang("t_appointments") }}
            </b-card-title>
            <!-- <b-card-sub-title class="mt-1">
              {{ lang("t_inboundRoutes") }}
            </b-card-sub-title> -->
          </b-card-header>

          <b-card-body class="px-0">
            <b-card-text class="d-flex mx-2">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>

                <b-form-input
                  v-model="searchQuery"
                  :placeholder="lang('t_search')"
                ></b-form-input>
              </b-input-group>

              <!-- <b-button
                size="sm"
                class="px-50 py-50 ml-2"
                variant="flat-secondary"
              >
                <b-icon icon="funnel"></b-icon>
              </b-button> -->
              <b-button
                :disabled="selected_item.length == 0"
                @click="
                  modal_multiple_record_details = true;
                  isEdit = false;
                "
                size="sm"
                class="px-50 py-50 ml-2"
                variant="flat-secondary"
              >
                <b-icon icon="pencil"></b-icon>
              </b-button>
            </b-card-text>
            <b-table
              :filter="searchQuery"
              small
              hover
              :fields="tableColumns"
              :items="filteredItems"
              responsive
              show-empty
              :empty-text="lang('t_noRecord')"
              :busy="records.length == 0"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <template #empty="scope">
                <b-container class="mt-5">
                  <b-row>
                    <b-col class="text-center text-muted mt-50">
                      <span>{{ scope.emptyText }}</span>
                    </b-col>
                  </b-row>
                </b-container>
              </template>
              <template
                v-for="field in tableColumns.filter(
                  (e) => !['note'].includes(e.key)
                )"
                v-slot:[`head(${field.key})`]="data"
              >
                <span>{{ field.label }}</span>

                <b-dropdown
                  variant="flat-secondary"
                  toggle-class="px-50 py-50"
                  class="ml-50"
                  right
                  no-caret
                  @hidden="onHidden"
                  boundary="window"
                  dropright
                >
                  <template #button-content>
                    <b-icon
                      :variant="'secondary'"
                      :icon="
                        Object.values(filters[field.key]).filter(
                          (e) => e == true
                        ).length == 0
                          ? 'funnel'
                          : 'funnel-fill'
                      "
                    ></b-icon>
                  </template>
                  <b-dropdown-form
                    style="max-height: 20rem; overflow-y: auto; z-index: 9999;position-absolute"
                    @submit.stop.prevent
                  >
                    <div
                      style="z-index: 9999;position-absolute"
                      v-for="(option, index) in getFilterOptions(field.key)"
                      :key="index"
                    >
                      <b-form-checkbox
                        @input="onHidden"
                        class="text-nowrap text-lowercase"
                        :id="`filter-${field.key}-${index}`"
                        v-model="filters[field.key][option]"
                      >
                        {{ option.split("@")[0] }}
                      </b-form-checkbox>
                    </div>
                  </b-dropdown-form>
                </b-dropdown>
              </template>

              <template #head(is_selected)="">
                <b-form-checkbox
                  @change="onChange"
                  class="custom-control-primary my-50"
                >
                  <span class="vs-checkbox">
                    <span class="vs-checkbox--check"> </span>
                  </span>
                </b-form-checkbox>
              </template>
              <template #cell(is_selected)="data">
                <b-form-checkbox
                  @change="selection(data.item)"
                  :checked="selected_item.filter((e) => e == data.item) > 0"
                  v-model="data.item.is_selected"
                  class="custom-control-primary"
                >
                  <span class="vs-checkbox">
                    <span class="vs-checkbox--check">
                      <!-- <i class="vs-icon feather icon-check" /> -->
                    </span>
                  </span>
                </b-form-checkbox>
              </template>
              <template #cell(note)="data">
                <b-button
                  :variant="`light-${data.item.event_color}`"
                  v-if="data.value.length > 0"
                  @click="openNote(data.value)"
                  class="py-50 px-50"
                >
                  <i
                    class="bi bi-sticky font-medium-1"
                    :class="`text-${data.item.event_color}`"
                  ></i>
                </b-button>
              </template>
              <template #cell(agent)="data">
                <div class="d-flex align-items-center">
                  <b-avatar
                    class="mr-50"
                    variant="light-secondary"
                    :text="`${data.value
                      .split('@')[0]
                      .split('.')[0][0]
                      .toUpperCase()}${data.value
                      .split('@')[0]
                      .split('.')
                      [data.value.split('.').length - 1][0].toUpperCase()}`"
                  >
                  </b-avatar>
                  <span>
                    {{ data.value.split("@")[0] }}
                  </span>
                </div>
              </template>
              <template #cell(status)="data">
                <feather-icon
                  :icon="data.value == '1' ? 'CheckIcon' : 'XIcon'"
                  size="12"
                  class="align-middle text-body"
                />
              </template>
              <template #cell(event_title)="data">
                <span>{{ data.value }}</span>
              </template>

              <!-- Column: Actions -->
              <!-- <template #cell(actions)="data">
                <b-dropdown
                  dropleft
                  boundary="viewport"
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="updateSelected(data.item)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="confirmText(data.item)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">{{
                      lang("t_delete")
                    }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template> -->
            </b-table>
          </b-card-body>

          <b-card-footer class="py-75 border-0">
            <div
              class="d-flex justify-content-between d-flex align-items-center"
            >
              <div class="d-flex align-items-center mb-0">
                <!-- <span class="text-nowrap"> Showing 1 to </span> -->
                <b-form-select
                  size="sm"
                  v-model="perPage"
                  :options="['10', '25', '50']"
                  class="mr-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap text-muted font-small-3">
                  {{
                    lang("t_total") +
                    " " +
                    records.length +
                    " " +
                    lang("t_row").toLowerCase()
                  }}
                </span>
              </div>

              <b-pagination
                size="sm"
                class="mb-0"
                v-model="currentPage"
                :per-page="perPage"
                align="right"
                :total-rows="filteredItems.length"
                first-number
                last-number
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="16" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="16" />
                </template>
              </b-pagination>
            </div>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="add_record"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="number"
            rules="required"
          >
            <b-form-group :label="lang('t_number')" label-for="number">
              <b-form-input
                id="number"
                v-model="currentData.number"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="target"
            rules="required"
          >
            <b-form-group
              :label="lang('t_target')"
              label-for="target"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="target"
                :options="users"
                :placeholder="lang('t_target')"
                :reduce="(val) => val.identifier"
                label="username"
                value="identifier"
              ></v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group :label="lang('t_name')" label-for="name">
              <b-form-input
                id="name"
                v-model="currentData.display_name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              :label="lang('t_queueMusicOnHold')"
              label-for="t_queueMusicOnHold"
            >
              <v-select
                v-model="currentData.music_on_hold"
                :options="announcements"
                label="display_name"
                :reduce="(val) => val.internal_name"
                :placeholder="lang('t_queueMusicOnHold')"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      :title="lang('t_editSelected')"
      centered
      v-model="modal_multiple_record_details"
      :ok-title="lang('t_edit')"
      :cancel-title="lang('t_delete')"
      @ok="updateAllSwal"
      ok-only
      @cancel="deleteAllSwal"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="target"
            rules="required"
          >
            <b-form-group
              :label="lang('t_agent')"
              label-for="target"
              :state="getValidationState(validationContext)"
            >
              <v-select
                id="target"
                v-model="target"
                :options="users"
                :placeholder="lang('t_agent')"
                :reduce="(val) => val.identifier"
                label="username"
                value="identifier"
              ></v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  BCardFooter,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroup,
  BCardText,
  BDropdownForm,
  BContainer,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useList from "./useList";
import storeModule from "../storeModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BContainer,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardFooter,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BCardText,
    vSelect,
    BDropdownForm,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      isEdit: false,
      currentData: {
        _id: "",
        number: "",
        target_parameters: "",
        target_command: "",
        domain: "",
        display_name: "",
        music_on_hold: "quiet",
      },
      modal_record_details: false,
      modal_multiple_record_details: false,
      required,
      alphaNum,
      email,
      password,
      target: null,
      records: [],
      users: [],
      selected_item: [],
      announcements: [],
      tableColumns: [
        {
          key: "is_selected", label: "", sortable: false, thStyle: { width: '5vh' }, value: false,
        },
        { key: "agent", label: globalThis._lang("t_agent"), sortable: true },
        {
          key: "event_title",
          label: globalThis._lang("t_finishCode"),
          sortable: true,
        },
        {
          key: "phone",
          label: globalThis._lang("t_remoteNumber"),
          sortable: true,
        },
        {
          key: "reminder_at",
          label: globalThis._lang("t_actionDate"),
          sortable: true,
        },
        {
          key: "reminder_time",
          label: globalThis._lang("t_actionTime"),
          sortable: true,
        },
        {
          key: "status",
          label: globalThis._lang("t_status"),
          sortable: true,
        },
        {
          key: "note",
          label: globalThis._lang("t_note"),
          sortable: true,
        },
        // {
        //   key: "actions",
        //   label: '',
        //   sortable: false,
        //   thStyle: { width: '5vh' }
        // },
      ],
      filters: {},
      filterDropdown: null,
    };
  },
  mounted: function () {
    this.get_records();
    this.get_users();
  },
  computed: {
    filteredItems() {
      let filtered = this.records;
      Object.keys(this.filters).forEach((field) => {
        const selectedOptions = Object.entries(this.filters[field])
          .filter(([key, value]) => value)
          .map(([key]) => key);
        if (selectedOptions.length > 0) {
          filtered = filtered.filter((item) => selectedOptions.includes(item[field]));
        }
      });
      return filtered;
    }
  },
  methods: {
    openNote(note) {
      this.$swal({
        html: note,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        showClass: {
          popup: "animate__animated animate__flipInX",
        },
        buttonsStyling: false,
      });
    },
    onHidden() {
      this.records = [...this.records];


    },
    toggleFilterDropdown(field) {
      this.filterDropdown = this.filterDropdown === field.key ? null : field.key;
    },

    getFilterOptions(key) {
      const options = new Set(this.records.map(item => item[key]));
      this.filters[key] = this.filters[key] || {};
      options.forEach(option => {
        if (this.filters[key][option] === undefined) {
          this.$set(this.filters[key], option, false);
        }
      });
      return options;

    },
    applyFilter(key, option) {
      this.filters[key] = option;
      this.filterDropdown = null;
      console.log('this.filters', this.filters);
    },
    onChange(val) {
      if (val == true) {
        this.records = this.records.map(e => ({ ...e, is_selected: true }));
        for (const item of this.records) {
          this.selection(item);
        }
        return;
      }
      this.records = this.records.map(e => ({ ...e, is_selected: false }));
      this.selected_item = [];
    },
    // rowClass(item, type) {
    //   if (item && item.event_color) {
    //     return `table-${item.event_color} table-style`;

    //   }
    // },
    deleteAllSwal() {
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteAll();
        }
      });
    },
    updateAllSwal() {
      this.$swal({
        title: this.lang("t_updateRecord"),
        text: this.lang("t_areYouSureYouWantToUpdateTheSelectedRecords"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.updateAll();
        }
      });
    },
    async deleteAll() {
      var response = (
        await this.$http_in.put(`/spv/v1/AppointmentMultiple`, {
          type: "delete",
          data: this.target,
          id_list: this.selected_item.map((e) => e._id),
        })
      ).data;
      this.get_records();
    },
    async updateAll() {
      if (this.target == null) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }
      var response = (
        await this.$http_in.put(`/spv/v1/AppointmentMultiple`, {
          type: "update",
          data: this.target,
          id_list: this.selected_item.map((e) => e._id),
        })
      ).data;
      this.get_records();
    },

    selection(item) {
      if (!this.selected_item.includes(item)) {
        this.selected_item.push(item);
      } else {
        console.log("else");
        this.selected_item = this.selected_item.filter((x) => x != item);
      }
    },
    get_records: async function () {
      this.is_progress = true;
      var response = (await this.$http_in.get(`/spv/v1/Appointment`)).data;
      console.log("InboundRoute", response);
      for (const item of response) {
        let parts = item.reminder_at.split(' ');
        item.reminder_at = parts[0];
        item.reminder_time = parts[1];
        item.status = item.status.toString();

      }
      this.records = response;
      this.is_progress = false;
    },

    get_users: async function () {
      var response = (await this.$http_in.get(`spv/v1/User`)).data;
      this.users = response;

    },
    check_data() {
      if (
        this.currentData.number == "" ||
        this.currentData.target_parameters == "" ||
        this.currentData.display_name == ""
      ) {
        return true;
      }
      return false;
    },
    add_record: async function () {
      if (this.check_data()) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }
      this.is_progress = true;
      var response;
      if (!this.isEdit) {
        response = (
          await this.$http_in.post(`/spv/v1/Appointment`, {
            data: JSON.stringify(this.currentData),
          })
        ).data;
      } else {
        response = (
          await this.$http_in.put(`/spv/v1/Appointment`, {
            InboundRouteInfo: JSON.stringify(this.currentData),
            data: JSON.stringify(this.currentData),
          })
        ).data;
      }
      this.is_progress = false;
      if (response && response[0].acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.reset_record();
      this.get_records();
    },
    delete_record: async function () {
      this.is_progress = true;
      console.log("this.currentData", this.currentData);
      var response = (
        await this.$http_in.delete(
          `/spv/v1/Appointment/${this.currentData._id}`
        )
      ).data;

      this.is_progress = false;
      if (response && response.acknowledged) {
        this.$swal({
          icon: "success",
          title: this.lang("t_processSuccess"),
          text: this.lang("t_customersHaveBeenSuccessfullyDeleted"),
          confirmButtonText: this.lang("t_ok"),
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }

      this.reset_record();
      this.get_records();
    },
    reset_record() {
      this.isEdit = false;
      this.target = null;
    },
    updateSelected(item) {
      this.currentData = item;
      console.log("item", item);
      this.isEdit = true;
      //this.isAddNewUserSidebarActive = true;
      this.modal_record_details = true;
    },
    confirmText(item) {
      this.currentData = item;
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.delete_record();
        }
      });
    },
    deleteRecord: async function () {
      // Vue.$toast('Kayıt silme.');

      this.fetchData();
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    const toast = Vue.$toast;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const blankUserData = {
      fullName: "",
      username: "",
      email: "",
      role: null,
      currentPlan: null,
      company: "",
      contact: "",
    };

    const resetuserData = () => {
      currentData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    const isAddNewUserSidebarActive = ref(false);

    const extensOptions = [
      { label: "123", value: "123" },
      { label: "456", value: "456" },
      { label: "9999", value: "9999" },
      { label: "654", value: "654" },
    ];
    const projectOptions = [
      { label: "Abc", value: "1" },
      { label: "Proje2", value: "2" },
      { label: "Proje3", value: "3" },
      { label: "Proje4", value: "4" },
    ];
    const profileOptions = [
      { label: "Admin", value: "1" },
      { label: "Profil2", value: "2" },
      { label: "Profil3", value: "3" },
      { label: "Profil4", value: "4" },
    ];

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    var {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      extensOptions,
      projectOptions,
      profileOptions,
      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      toast,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
